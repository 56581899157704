import React, {useState, useContext, useEffect, useRef} from "react"
import env from "react-dotenv";
import Dialog from '@mui/material/Dialog';
import { GlobalContext } from "../../context/GlobalStateContext";
import PropietarioContext from '../../context/PropietarioContext';
import ItemCard from "../Dashboard/ItemCard/ItemCard"
import { getArticlesFromCart, enviarACarrito } from "../../servicios/item";

const CarritoEliminar = () => {
  const { accessToken } = useContext(GlobalContext)
  const { propietario } = useContext(PropietarioContext)
  const [articulos, setArticulos] = useState([])
  const [sections, setSections] = useState({
    acceptance: false,
    formSection: false
  });
  const [loaded, setLoaded] = useState(false);
  const [formData, setFormData] = useState({
    reason: '',
    terms: false,
  })
  const [errorFormData, setErrorFormData] = useState({
    reason: '',
    terms: '',
  })
  const [paymentMethod, setPaymentMethod] = useState('later')
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [finalBtn, setFinalBtn] = useState(true)
  const culqiContainer = useRef(null)
  const [responseModal, setResponseModal ] = useState(false)
  const [responseDataModal, setResponseDataModal ] = useState({
    type: '',
    title: '',
    message: ''
  })


  const getCarData = async () => {
    try {
      const data = await getArticlesFromCart('3', propietario?.idPropietario, accessToken);
      const almacenados = data.filter(article => article.estadoArticulo === 'Almacenado');
      setArticulos(almacenados)
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  const deleteItemFromCar = (data) => {
    setPaymentMethod('')
    enviarACarrito(data.idArticulo, 0, accessToken)
    setArticulos(articulos.filter(item => item.idArticulo !== data.idArticulo))
  }

  const showSection = (type, val) => {
    setSections(prevSections => ({
      ...prevSections,
      [type]: val
    }));
  }

  const handleFormChange = (e) => {
    const { name, type, checked, value } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  }

  const validateForm = (types) => {
    const errors = {};
    setLoaded(true)
  
    types.forEach(type => {
      switch(type) {
        case 'reason':
          if(formData.reason === '') {
            errors.reason = 'El campo es requerido';
          } else {
            errors.reason = ''
          }
          break;
        case 'terms':
          if(!formData.terms) {
            errors.terms = 'Debe aceptar los terminos';
          } else {
            errors.terms = ''
          }
          break;
        default:
      }
    });
  
    setErrorFormData(prevErrors => ({
      ...prevErrors,
      ...errors
    }));

    const errorsVal = Object.values(errors).every(valor => valor === '');
    if(errorsVal) {
      return true
    } else {
      showSection('formSection', false)
      return false
    }
  };

  const handleForm = () => {
    const isValidate = validateForm(['reason', 'terms']);
    if(isValidate) {
      showSection('formSection', true)
    }
  }

  const loadScripts = () => {
    const culqiScript = document.createElement("script");

    culqiScript.setAttribute("src", "https://js.culqi.com/checkout-js");

    document.head.appendChild(culqiScript);
  };

  const startCulqi = (orderNumber) => {
    setConfirmationModal(false)
    const overlay = document.getElementById('c-loading')
    overlay.classList.remove('d-none')
    window.setTimeout(() => {
      overlay.classList.add('d-none')
      window.scrollTo({
        top: window.scrollY + 400,
        behavior: 'smooth'
      });
      const settings = {
          title: 'Culqi  store 2',
          currency: 'PEN',
          amount: articulos.reduce((total, item) => total + item.monto, 0) * 100,
      }

      const paymentMethods = {
            tarjeta: true,
        }

      const options = {
        lang: 'auto',
        installments: true,
        modal: false,
        container: "#culqi-container",
        paymentMethods: paymentMethods,
        paymentMethodsSort: Object.keys(paymentMethods),
      }

      const client = {
        email: propietario.email,
      }

      const appearance = {
          theme: "default",
          hiddenCulqiLogo: false,
          hiddenBannerContent: true,
          hiddenBanner: false,
          hiddenToolBarAmount: false,
          menuType: "sidebar",
          buttonCardPayText: "Pagar",
          logo: 'https://sapiens.innovdevelopers.com/wp-content/themes/edtech/app/static/public/assets/logo-footer-4d7f56d8.png',
          defaultStyle: {
          bannerColor: "blue",
          buttonBackground: "yellow",
          menuColor: "pink",
          linksColor: "green",
          buttonTextColor: "blue",
          priceColor: "red",
          },
          variables: {
          fontFamily: "monospace",
          fontWeightNormal: "500",
          borderRadius: "8px",
          colorBackground: "#0A2540",
          colorPrimary: "#EFC078",
          colorPrimaryText: "#1A1B25",
          colorText: "white",
          colorTextSecondary: "white",
          colorTextPlaceholder: "#727F96",
          colorIconTab: "white",
          colorLogo: "dark",
          soyUnaVariable: "blue",
          },
          rules: {
            ".Culqi-Main-Container": {
                background: "#fff",
                fontFamily: "var(--fontFamily)",
            },
            ".Culqi-ToolBanner": {
                background: "#F94700",
                fontFamily: "var(--fontFamily)",
                color: "white",
            },
            ".Culqi-Toolbar-Price": {
                color: "#494949",
                fontFamily: "var(--fontFamily)",
            },
            ".Culqi-Toolbar-Price .Culqi-Icon": {
                color: "blue",
            },
            ".Culqi-Main-Method": {
                background: "#fff",
                padding: "10px 20px",
                color: "#494949",
            },
            ".Culqi-Text-Link": {
                color: "red",
            },
            ".Culqi-Text-Link .Culqi-Icon": {
                color: "blue",
            },
            ".Culqi-message": {
                color: "#494949",
            },
            ".Culqi-message .Culqi-Icon": {
                color: "#494949",
            },
            ".Culqi-message-warning": {
                background: "yellow",
                color: "red",
            },
            ".Culqi-message-info": {
                background: "white",
                color: "black",
            },
            ".Culqi-message-error": {
                background: "black",
                color: "pink",
            },
            ".Culqi-message-error .Culqi-Icon": {
                color: "yellow",
            },
            ".Culqi-Label": {
                color: "black",
                fontWeight: "700",
                marginBottom: "20px",
            },
            ".Culqi-Input": {
                border: "1px solid black",
                color: "black",
            },
            ".Culqi-Input:focus": {
                border: "2px solid black",
            },
            ".Culqi-Input.input-valid": {
                background: "#e2e2e2",
            },
            ".Culqi-Input-Icon-Spinner": {
                color: "red",
            },
            ".Culqi-Input-Select": {
                border: "1px solid black",
                color: "black",
            },
            ".Culqi-Input-Select-Options-Hover": {
                color: "red",
                background: "black",
            },
            ".Culqi-Input-Select-Selected":{
                color: "green",
            },
            ".Culqi-Input-Select.active": {
                border: "1px solid red",
                background: "pink",
            },
            ".Culqi-Input-Select-Options": {
                background: "gray",
            },
            ".Culqi-Button": {
                background: "red",
            },
            ".Culqi-Menu": {
                color: "blue",
            },
            ".Culqi-Menu .Culqi-Icon": {
                color: "green",
            },
            ".Culqi-Menu-Selected": {
            },
            ".Culqi-Menu-Selected .Culqi-Icon": {
                color: "red",
            },
            ".Culqi-Menu-Options": {
                background: "orange",
            },
            ".Culqi-Menu-Options-Hover": {
                background: "green",
                color: "red",
            },
            ".Culqi-Menu-Options-Hover .Culqi-Icon": {
                color: "blue",
            },
          },
      };

      const handleCulqiAction = () => {
        if (Culqi.token) {
          handCheckout(Culqi.token.id, orderNumber)
        } else {
          const errorMessage = Culqi.error
          console.log(errorMessage)
        }
      }

      const config = {
        settings,
        client,
        options,
        appearance,
      };

      const Culqi = new CulqiCheckout('pk_test_07e6c53c37ad8044', config);
      Culqi.culqi = handleCulqiAction;
      Culqi.open()
    }, 2000);
  };

  const handCheckout = (token, orderNumber) => {
    const overlay = document.getElementById('c-overlay')
    overlay.classList.remove('d-none')
    let message
    
    // if(token) {
    //   overlay.classList.add('d-none')
    //   window.location.href = `/envio/${orderNumber}/success`
    // }


    if(token) {
      try {
        const request = env.API_URL+"/payments";
        fetch(request, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer "+accessToken,
          },
          body: JSON.stringify({
            // items: articulos, //falta
            // address: addressObject, //falta
            // data : date, //falta

            token,
            amount: (articulos.reduce((total, item) => total + item.monto, 0))*100, //no se deberia mandar por fEnd
            currencyCode : "PEN",
            email: propietario.email,
            description : "Camiseta prueba 2 culqui", // ??
            idOGL : orderNumber
          }),
        })
          .then((response) => {
            // if (response.status === 201 || response.status < 300) {
            if (response.status === 200) {
              return response.json();
            }
            return response.text().then((text) => { throw new Error(text); });
          })
          .then((data) => {
            console.log(data, 'dataaaaaa')
            if (data.status) {
              overlay.classList.add('d-none')
              window.location.href = `/envio/${orderNumber}/success`
            } else {
              overlay.classList.add('d-none')
              message = `${data.message} (status: ${data.code})`
              toggleResponseDataModal('error', 'Error al generar la orden', message)
              setResponseModal(true)
            }
          })
          .catch((error) => {
            overlay.classList.add('d-none')
            try {
              message = JSON.parse(error.message)?.message;
            } catch (err) {
              message = 'Error al procesar la compra, intentelo nuevamente';
            }
            toggleResponseDataModal('error', 'Error al generar la orden', message)
            setResponseModal(true)
          });
      } catch (err) {
        overlay.classList.add('d-none')
        if (err) {
          message = 'No se logró continuar con la compra, vuelva a intentarlo por favor.'
          toggleResponseDataModal('error', 'Error al generar la orden', message)
          setResponseModal(true)
        }
      }
    } else {
      console.log('no token')
    }
  }
  
  const obtenerServicio = () =>{
    const request = env.API_URL+"/obtenerServicio"
    fetch(request, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+accessToken
      },
      body: JSON.stringify({
        fechaServicio: new Date(Date.now()).toISOString().slice(0,10)
      }),
    })
    .then(res => res.json())
    .then(data => {
      if(data.length > 0) createOrder(data[0].idServicio)
      else crearServicio()
    })
  }

  const crearServicio = () =>{
    const request = env.API_URL+"/crearServicio"
    fetch(request, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+accessToken
      },
      body: JSON.stringify({
        fechaServicio: new Date(Date.now()).toISOString().slice(0,10)
      }),
    })
    .then(() => obtenerServicio())
  }

  const createOrder = (idServicio) => {
    setConfirmationModal(false)
    const overlay = document.getElementById('c-overlay')
    overlay.classList.remove('d-none')
    let message;

    try {
      const request = env.API_URL_V2+"/crearOGL";
      fetch(request, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer "+accessToken,
        },
        body: JSON.stringify({
          idPropietario: propietario.idPropietario, 
          idServicio: idServicio, // ?
          idArticulos: articulos.map(articulo => articulo.idArticulo), 
          direccion: '-', 
          volumen: articulos.reduce((total, item) => total + parseFloat(item.volumen), 0),
          fecha : new Date(Date.now()).toISOString().slice(0,10),
          tipo : "EL",
          comentario: formData.reason,
          aceptoTerminos: formData.terms
        }),
      })
        .then((response) => {
          if (response.status === 201 || response.status < 300) {
            return response.json();
          }
          return response.text().then((text) => { throw new Error(text); });
        })
        .then((data) => {
          if (data.idOGL) {
              let order = data.idOGL
                if(paymentMethod === 'now') {
                  overlay.classList.add('d-none')
                  setFinalBtn(false)
                  culqiContainer.current.classList.remove('d-none')
                  startCulqi(order)
                } else {
                  setTimeout(()=> {
                    overlay.classList.add('d-none')
                    window.location.href = `/eliminar/${order}/success`
                  },2000)
                  
                }
            
          } else {
            overlay.classList.add('d-none')
            message = `${data.message} (status: ${data.code})`
            toggleResponseDataModal('error', 'Error al generar la orden', message)
            setResponseModal(true)
          }
        })
        .catch((error) => {
          overlay.classList.add('d-none')
          try {
            message = JSON.parse(error.message)?.message;
          } catch (err) {
            message = 'Error al procesar la compra, intentelo nuevamente';
          }
          toggleResponseDataModal('error', 'Error al generar la orden', message)
          setResponseModal(true)
        });
    } catch (err) {
      overlay.classList.add('d-none')
      if (err) {
        message = 'No se logró continuar con la compra, vuelva a intentarlo por favor.'
        toggleResponseDataModal('error', 'Error al generar la orden', message)
        setResponseModal(true)
      }
    }
  }

  const toggleResponseDataModal = (type, title, message) => {
    setResponseDataModal({
      type: type,
      title: title,
      message: message
    });
  }

  const cancelConfirmation = () => {
    setPaymentMethod()
    setConfirmationModal(false)
  }

  const clearView = () => {
    setSections({
      addressSection: false,
      dateSection: false,
      formSection: false
    })
    setPaymentMethod('')
    setArticulos([])
  }

  useEffect(()=> {
    loadScripts()
  }, [])

  useEffect(() => {
    getCarData()
  }, [propietario])

  useEffect(() => {
    const toValidate = Object.keys(formData).filter(key => formData[key] !== '')
    if(loaded) {
      validateForm(toValidate)
    }
  }, [loaded, formData])

  useEffect(()=>{
    setFinalBtn(true)
  }, [paymentMethod])

  return(
  <div className="c-sendcar c-catalogo">
    <div className="container">
      <div className="d-lg-flex align-items-center justify-content-between mt-4 mb-4">
        <div>
          <h4 className="c-catalogo__subtitle">Prelistado de articulos para una orden de envio</h4>
          <h1 className="c-catalogo__title mb-lg-0">Artículos para eliminación</h1>
        </div>
      </div>
      {articulos.length > 0 && 
        <div className='c-stepList d-flex flex-column'>
          <div className='c-step'>
            <div className="d-lg-flex align-items-center justify-content-between  mb-1">
              <div className='d-flex align-items-center justify-content-start mb-3 mb-lg-0'>
                <div className='c-step__number me-2'>
                  1
                </div>
                <span className='c-step__label'>Selecciona los artículos para enviar </span>
              </div>
            </div>

            {articulos !== null ? (
              <div className={`${articulos.length > 8 ? 'c-scrool' : ''}`}>
                <div className='row gy-4'>
                  {articulos.map((article, index) => (
                    <div key={index} className='col-12 col-md-6 col-lg-4 col-xl-3'>
                      <ItemCard 
                        article={article} 
                        sendItemData={deleteItemFromCar}
                        type="lista" 
                      />
                    </div>
                  ))}
                </div>
              </div>
            ): null}

            <div className="text-end mt-3">
              <button onClick={()=> showSection('acceptance', true)} className="c-button c-button--primary">
                Siguiente
              </button>
            </div>
          </div>
    
          {sections.acceptance &&
            <div className='c-step'>
              <div className='d-flex align-items-center justify-content-start mb-1'>
                <div className='c-step__number me-2'>
                  2
                </div>
                <span className='c-step__label'>Formulario</span>
              </div>
              <div className='c-step__description'>
                Llena los campos del formulario
              </div>
              <div className='c-form w-100'>
                  <div className='row gy-3'>
                    <div className="col-12">
                      <div className={`c-input-group ${errorFormData.reason ? 'error' : ''}`}>
                        <label className="mb-2">¿Por que quieres eliminar estos articulos de tu lista?</label>
                        <textarea
                          name="reason"
                          value={formData.reason} 
                          onChange={handleFormChange}
                          onBlur={handleFormChange}
                          type="text"  
                          placeholder='Escribe aqui'/>
                        <span className='c-error'>{errorFormData.reason}</span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className={`c-input-group ${errorFormData.terms ? 'error' : ''}`}>
                        <label>¿Estás seguro de eliminar estos articulos?</label>
                        {/* <textarea
                          name="reason"
                          value={formData.reason} 
                          onChange={handleFormChange}
                          onBlur={handleFormChange}
                          type="text"  
                          placeholder='Escribe aqui'/> */}

                          <label htmlFor="terms" className="d-flex align-items-center justify-content-start">
                            <input 
                              type='checkbox' 
                              id='terms'
                              name="terms"
                              checked={formData.terms}
                              onChange={handleFormChange}
                              className='c-checkbox w-auto me-2' />
                              <span>
                                Acepto los términos y condiciones
                              </span>
                          </label>

                        <span className='c-error'>{errorFormData.terms}</span>
                      </div>
                    </div>
                  </div>
                </div>
              <div className="text-end mt-3">
                <button onClick={()=> handleForm()} disabled={!(formData.reason && formData.terms)} className="c-button c-button--primary">
                  Siguiente
                </button>
              </div>
            </div>
          }
          {sections.formSection &&(formData.reason && formData.terms) &&
            <div className='c-step'>
              <div className='d-flex align-items-center justify-content-start mb-1'>
                <div className='c-step__number me-2'>
                  4
                </div>
                <span className='c-step__label'> Selecciona la forma de pago </span>
              </div>
              <div className='c-step__description mb-3'>
                Verifica los datos  y selecciona el metodo de pago
              </div>
              <div className="c-resume mb-3">
                <div className='c-step__description mb-3'>
                  Tiene <strong>{articulos.length} artículo(s)</strong> seleccionados: 
                </div>
                {articulos !== null ? (
                  <div className='c-detailList mb-3'>

                    {articulos.map((article, index) => (
                      <div key={index} className='c-detailList__item d-flex align-items-center justify-content-center'>
                        <figure className='c-detailList__figure m-0'>
                          <img src={article.imagenRecogida !== null
                            ? `https://storange-images.s3.amazonaws.com/appsheet/data/StorangeGestionLogistica_5361150/${article.imagenRecogida}`
                            : 'https://pad.minem.gob.pe/Proyecta_CMS/Recursos/ProyectoImg/SinImagen.png'} alt={article.titulo} className='w-100 h-100 object-fit-cover' />
                        </figure>
                        <div className='c-detailList__data d-flex align-items-center justify-content-between'>
                          <span>{article.titulo}</span>
                          {/* <span>S/ {article.monto}</span> */}
                        </div>
                      </div>
                    ))}
                  </div>
                ): null}
                <div className="d-flex flex-column gap-3">
                  <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                    <span>
                      <span data-icon="ep:success-filled" className='iconify'></span>
                      <span className='ms-2'>m<sup>3</sup> totales:</span>
                    </span>
                    <span>{parseFloat(articulos.reduce((total, item) => total + parseFloat(item.volumen), 0).toFixed(2))} m<sup>3</sup></span>
                  </div>
                  <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                    <span>
                      <span data-icon="ep:success-filled" className='iconify'></span>
                      <span className='ms-2'>Total:</span>
                    </span>
                    <span>S/ {articulos.reduce((total, item) => total + parseFloat(item.volumen), 0) < 1 ? 20 : (articulos.reduce((total, item) => total + parseFloat(item.volumen), 0) * 20).toFixed(2) }</span>
                  </div>
                </div>
              </div>
              <div className="c-payOptions d-flex flex-column mt-3">
                <div className="c-payOptions__title text-center">
                  Selecciona la forma de pago:
                </div>
                {
                  false ??
                  <button onClick={()=> setPaymentMethod('now')} className={`c-payOption d-flex align-items-center justify-content-start ${paymentMethod === 'now' ? 'active' : ''}`}>
                    <div className="c-payOption__icon">
                      <span data-icon="mingcute:card-pay-fill" className='iconify'></span>
                    </div>
                    <div className="c-payOption__resume">
                      <h4 className="c-payOption__title">
                        Pagalo ahora
                      </h4>
                      <div className="c-payOption__description">
                        Puedes pagar con Visa, Mastercard, etc
                      </div>
                    </div>
                  </button>
                }

                <button  onClick={()=> setPaymentMethod('later')} className={`c-payOption d-flex align-items-center justify-content-start ${paymentMethod === 'later' ? 'active' : ''}`}>
                  <div className="c-payOption__icon">
                    <span data-icon="mingcute:time-fill" className='iconify'></span>
                  </div>
                  <div className="c-payOption__resume">
                    <h4 className="c-payOption__title">
                      Pagalo después
                    </h4>
                    <div className="c-payOption__description">
                      El monto se cargará en tu siguiente factura
                    </div>
                  </div>
                </button>
              </div>
              {finalBtn && 
                <div className="text-end mt-3">
                  <button onClick={()=> setConfirmationModal(true)} className="c-button c-button--primary" disabled={paymentMethod === 'now' || paymentMethod === 'later' ?  false : true}>
                    Continuar
                  </button>
                </div>
              }

              {paymentMethod === 'now' ? 
                <div id="culqi-container" ref={culqiContainer}  className={`culqi-container mt-4 ${paymentMethod === 'now' ? '' : 'd-none'}`}>
                </div>
              : null}
            </div>
          }
        </div>}
    </div>

    <div id="c-overlay" className="c-overlay d-none">
      Enviando...
    </div>

    <div id="c-loading" className="c-overlay d-none">
      Cargando...
    </div>


    <Dialog
      open={confirmationModal}
      onClose={() => setConfirmationModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='c-modal c-modal--alert'>
        <div className='c-modal__content'>
          <div className='c-modal__icon c-modal__icon--alert'>
            <span data-icon="ep:success-filled" className='iconify'></span>
          </div>
          
          <div className='c-modal__title mb-3'>
          ¿Está seguro que desea eliminar estos articulos de su lista?
          </div>
          <p className='c-modal__description'>
          Los artículos seleccionados se elimianran de forma permanente de su lista sin opción a desahacer la acción
          </p>
        </div>
        <div className='c-modal__footer d-flex align-items-center justify-content-end gap-3'>

          <button className='c-button c-button--white-gray ' onClick={() => cancelConfirmation()} >Cancelar</button>

          <button className='c-button c-button--white-gray' onClick={() => obtenerServicio()} >Sí, eliminar</button>
        </div>
      </div>
    </Dialog>

    <Dialog
      open={responseModal}
      onClose={() => setResponseModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='c-modal c-modal--alert'>
        <div className='c-modal__content'>

        {responseDataModal.type === 'success' ? (
          <div className='c-modal__icon c-modal__icon--success'>
          <span data-icon="ep:success-filled" className='iconify'></span>
        </div>
        ) : (
          <div className='c-modal__icon c-modal__icon--alert'>
            <span data-icon="mingcute:alert-fill" className='iconify'></span>
          </div>
        )}
          
          <div className='c-modal__title mb-3'>
            {responseDataModal.title}
          </div>
          <p className='c-modal__description'>
            {responseDataModal.message}
          </p>
        </div>
        <div className='c-modal__footer d-flex align-items-center justify-content-end'>
          <button className='c-button c-button--white-gray' onClick={() => setResponseModal(false)} >Cerrar</button>
        </div>
      </div>
    </Dialog>

    {/* <Dialog
      open={responseModal}
      onClose={() => setResponseModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='c-modal c-modal--alert'>
        <div className='c-modal__content'>

        {responseDataModal.type === 'success' ? (
          <div className='c-modal__icon c-modal__icon--success'>
          <span data-icon="ep:success-filled" className='iconify'></span>
        </div>
        ) : (
          <div className='c-modal__icon c-modal__icon--alert'>
            <span data-icon="ep:success-filled" className='iconify'></span>
          </div>
        )}
          
          <div className='c-modal__title mb-3'>
            {responseDataModal.title}
          </div>
          <p className='c-modal__description'>
            {responseDataModal.message}
          </p>
        </div>
        <div className='c-modal__footer d-flex align-items-center justify-content-end'>
          <button className='c-button c-button--white-gray' onClick={() => setResponseModal(false)} >Cerrar</button>
        </div>
      </div>
    </Dialog> */}
  </div>)
}

export default CarritoEliminar