// import React, { useContext, useEffect } from 'react'
// import { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import Swal from 'sweetalert2'
// import { GlobalContext } from '../../../context/GlobalStateContext'
// import ArticlesList from '../ArticlesList/ArticlesList'
// import Filters from '../Filters/Filters'
// import './DashboardContainer.css'
// import PropietarioContext from '../../../context/PropietarioContext'
// import OrdenContext from '../../../context/OrdenContext'

// const DashboardContainer = () => {

//   const { buscarPropietario, propietario } = useContext(PropietarioContext)

//   const { buscarOrdenes, ordenesEnProgreso } = useContext(OrdenContext)

//   const { filtersDisplayed, setFiltersDisplayed, swalCambio, setSwalCambio, change, setChange, setLoaderState } = useContext(GlobalContext)

//   const navigate = useNavigate()

//   const [resize, setResize] = useState(window.innerWidth < 1000)

//   const handleResize = () => {
//     setResize(window.innerWidth < 1000)
//     if(window.innerWidth > 1000) setFiltersDisplayed(true)
//   }

//   useEffect(() => {
//     if (propietario === null ^ localStorage.getItem('trustedUser')){
//       buscarPropietario()
//     }
//     else{
//       if(propietario.primerCambio === 0 && swalCambio){
//         setSwalCambio(false)
//         setTimeout(() => {
//           Swal.fire({
//             title: `Hola ${propietario.nombre}!`,
//             text: 'Su cuenta nueva tiene una contraseña predeterminada, es recomendable realizar un cambio de contraseña',
//             icon: 'info',
//             showDenyButton: true,
//             showCancelButton: false,
//             confirmButtonText: 'Ok, vamos',
//             denyButtonText: 'Más tarde',
//           }).then((result) => {
//             if (result.isConfirmed) {
//               setChange(!change)
//               setLoaderState(0)
//               setTimeout(() => navigate('/perfil'), 500)
//             }
//             setSwalCambio(false)
//           })
//         }, 1500)
//       }
//     }
    
//     if (ordenesEnProgreso === null) buscarOrdenes()

//     window.addEventListener('resize', handleResize)

//     return () => {
//       window.removeEventListener('resize', handleResize)
//     }
//   }, [])

//   return (
//     <div className={filtersDisplayed && resize ? 'dashboardContainer displayed' : 'dashboardContainer'}>
//       <Filters />
//       <ArticlesList />
//     </div>
//   )
// }

// export default DashboardContainer

import React from 'react'
import env from "react-dotenv";
import { useState, useContext, useEffect } from 'react'
import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import { GlobalContext } from '../../../context/GlobalStateContext'
import ItemCard from '../ItemCard/ItemCard';
import PropietarioContext from '../../../context/PropietarioContext'


const DashboardContainer = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180,
      },
    },
  };

  const states = [
    'Almacenado',
    'En venta',
    'Proceso de envio',
    'Contigo',
  ];

  const volumes = [
    '0.05',
    '0.10',
    '2'
  ];

  const types = [
    'Suelto',
    'Caja'
  ];

  const categories = [
    '--',
  ];

  const orders = [
    'ASC',
    'DESC'
  ];

  const preslists = [
    'No enviado',
    'Enviado',
    'Eliminacion',
    'Venta'
  ];

  const [textFilter, setTextFilter ] = useState('')
  const [stateFilter, setStateFilter] = useState('');
  const [volumeFilter, setVolumenFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [orderFilter, setOrderFilter] = useState('');
  const [preslistFilter, setPreslistFilter] = useState('');
  const [filterSidebar, setfilterSidebar] = useState(false);
  const [stateModal, setStateModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { volumen, propietario } = useContext(PropietarioContext)

  const [ articulos, setArticulos ] = useState([])
  const [ totalArticles, setTotalArticles ] = useState(0)
  const [ nextPage, setNextPage ] = useState('/articulovirtual/1')
  const [ centinelPage, setCentinelPage ] = useState('')
  const { accessToken } = useContext(GlobalContext)

  const listfilters = {
    estado: stateFilter,
    volumen: volumeFilter,
    tipo: typeFilter,
    categoria: categoryFilter,
    orden: orderFilter,
    prelistado: preslistFilter,
  }
  const allFiltersEmpty = Object.values(listfilters).every(value => !value);

  const changeFilter = (filter,newValue) => {
    setCentinelPage('/articulovirtual/1')
    setNextPage('/articulovirtual/1')
    switch (filter) {
      case 'estado':
        setStateFilter(newValue);
        break;
      case 'volumen':
        setVolumenFilter(newValue);
        break;
      case 'tipo':
        setTypeFilter(newValue);
        break;
      case 'categoria':
        setCategoryFilter(newValue);
        break;
      case 'orden':
        setOrderFilter(newValue);
        break;
      case 'prelistado':
        setPreslistFilter(newValue);
        break;
      default:
        console.log('Estado no reconocido');
    }
  }

  const handleFilterChange = (event, filter) => {
    const { target: { value }, } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    changeFilter(filter, newValue)
  };

  const handleInputChange = (event) => {
    setCentinelPage('/articulovirtual/1')
    setNextPage('/articulovirtual/1')
    setTextFilter(event.target.value);
  };

  const toggleDrawer = (value) => () => {
    setfilterSidebar(value);
  };

  const toggleModal = (value) => () => {
    setStateModal(value);
  }

  const handleChangeAccordeon = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const cargarMasProductos = () => {
    setNextPage(centinelPage)
  };

  const encodePetition = (parameters, formBody = []) => {
    for (let property in parameters) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(parameters[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    return formBody.join("&")
  }

  const getArticles = async () => {
    let details = { 
      idPropietario: propietario?.idPropietario, 
      filter: textFilter,
      estado: stateFilter[0] ? stateFilter[0] : '',
      volumen: volumeFilter[0] ? volumeFilter[0] :'',
      tipo: typeFilter[0] ? typeFilter[0] : '',
      categoria:categoryFilter[0] ? categoryFilter[0] : '',
      shoppingCartType: preslistFilter[0] ? preslistFilter[0] : '',
      order: orderFilter[0] ? orderFilter[0] : '',   
    }

    await fetch(env.API_URL+nextPage, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        if(res.articulos) {
          if(nextPage === '/articulovirtual/1') {
            setArticulos(res.articulos)
            setCentinelPage('/articulovirtual/2')
            if(res.pagination) {
              setTotalArticles(res.pagination.total)
            }
          } else {
            let newArray = [...articulos, ...res.articulos];
            setArticulos(newArray)
          }

          if(res.pagination.nextPage) {
            setCentinelPage(res.pagination.nextPage.split('v1')[1])
          } else {
            setCentinelPage(res.pagination.nextPage)
          }
        } else {
          setArticulos([])
          setCentinelPage('/articulovirtual/1')
          setTotalArticles(0)
          nextPage('')
        }
      })
      .catch((err) =>{
        console.log('no autorizado', err)
      })
      .finally(() => console.log('Finalizado'))
  }

  useEffect(() => {
    getArticles()
  }, [propietario, textFilter, stateFilter, volumeFilter, typeFilter, categoryFilter, preslistFilter, orderFilter, nextPage]);

  return (
    <div className='c-home'>
      <div className='container'>
        <div className='c-home__top d-flex flex-column'>
          <div className='c-welcome'>
            <div className='row align-items-center'>
              <div className='col-6 col-lg-12'>
                <div className='c-welcome__text'>Hola {propietario?.nombre}! actualmente te estamos liberando de : </div>
              </div>
              <div className='col-6 col-lg-12'>
                <div className='c-welcome__number text-center text-lg-start'>
                  <span>{volumen}</span> m<sup>3</sup>
                </div>
              </div>
            </div>
          </div>
          <div className='c-horizontal-scroll'>
            <div>
              <div className='c-filters d-flex align-items-center'>
                <div className='c-select'>
                  <Select
                      displayEmpty
                      value={stateFilter}
                      onChange={(event) => handleFilterChange(event, 'estado')}
                      MenuProps={MenuProps}
                      IconComponent={ExpandMoreIcon}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Estado</span>;
                        }

                        return selected.join(', ');
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {states.map((state) => (
                        <MenuItem
                          key={state}
                          value={state}
                        >
                          {state}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div className='c-select'>
                  <Select
                      displayEmpty
                      value={volumeFilter}
                      onChange={(event) => handleFilterChange(event, 'volumen')}
                      MenuProps={MenuProps}
                      IconComponent={ExpandMoreIcon}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Volumen</span>;
                        }

                        return selected.join(', ');
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {volumes.map((volume) => (
                        <MenuItem
                          key={volume}
                          value={volume}
                        >
                          {volume}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div className='c-select'>
                  <Select
                      displayEmpty
                      value={typeFilter}
                      onChange={(event) => handleFilterChange(event, 'tipo')}
                      MenuProps={MenuProps}
                      IconComponent={ExpandMoreIcon}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Tipo</span>;
                        }

                        return selected.join(', ');
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {types.map((type) => (
                        <MenuItem
                          key={type}
                          value={type}
                        >
                          {type}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                {/* temporal  */}
                {/* <div className='c-select'>
                  <Select
                      displayEmpty
                      value={categoryFilter}
                      onChange={(event) => handleFilterChange(event, 'categoria')}
                      MenuProps={MenuProps}
                      IconComponent={ExpandMoreIcon}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Categorías</span>;
                        }

                        return selected.join(', ');
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {categories.map((category) => (
                        <MenuItem
                          key={category}
                          value={category}
                        >
                          {category}
                        </MenuItem>
                      ))}
                  </Select>
                </div> */}
                <div className='c-select'>
                  <Select
                      displayEmpty
                      value={orderFilter}
                      onChange={(event) => handleFilterChange(event, 'orden')}
                      MenuProps={MenuProps}
                      IconComponent={ExpandMoreIcon}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Orden</span>;
                        }

                        return selected.join(', ');
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {orders.map((order) => (
                        <MenuItem
                          key={order}
                          value={order}
                        >
                          {order}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div className='c-select'>
                  <Select
                      displayEmpty
                      value={preslistFilter}
                      onChange={(event) => handleFilterChange(event, 'prelistado')}
                      MenuProps={MenuProps}
                      IconComponent={ExpandMoreIcon}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Prelistado</span>;
                        }

                        return selected.join(', ');
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {preslists.map((preslist) => (
                        <MenuItem
                          key={preslist}
                          value={preslist}
                        >
                          {preslist}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div>
                  <button onClick={toggleDrawer(true)} className='c-allfilter d-flex align-items-center'>
                    <span>Todos los filtros</span>
                    <span data-icon="mage:filter-fill" className='iconify'></span>
                  </button>
                </div>
                <div className="flex-grow-1">
                  <div className='c-search-group d-flex w-100'>
                      <input 
                        value={textFilter}
                        onChange={handleInputChange} 
                        placeholder="Buscar" type="text" />
                      <button>
                        <span data-icon="ic:round-search" className='iconify'></span>
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <span className='c-line'></span>
          <div className={`c-filterSelections 
          d-flex align-items-center ${allFiltersEmpty ? '' : 'mb-4'}`}>
            {Object.entries(listfilters).map(([key, filter], index) => (
              filter ? (<div className='c-chip' key={index}>
              <span>{filter}</span>
              <button onClick={() => changeFilter(key, '')}>
                <span data-icon="akar-icons:cross" className='iconify'></span>
              </button>
              </div>):null
            ))}
          </div>
        </div>
          {articulos !== null ? (
            <div>
              <div className='row gy-4'>
                {articulos.map((article, index) => (
                  <div key={index} className='col-12 col-md-6 col-lg-4 col-xl-3'>
                    <ItemCard article={article} type="dashboard" />
                  </div>
                ))}
              </div>
              <div className='c-pagination d-flex justify-content-center align-items-center w-100 mt-5'>
                <button onClick={cargarMasProductos} disabled={centinelPage ? false : true} className='c-button c-button--primary'>
                  Ver más
                </button>

                <div  className='c-chip'>
                  <span>{articulos.length}</span> / <span>{totalArticles}</span>
                </div>

              </div>
            </div>
          ): null}

      </div>

      <Dialog
        open={stateModal}
        onClose={toggleModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='c-modal c-modal--alert'>
          <div className='c-modal__content'>
            <div className='c-modal__icon c-modal__icon--alert'>
              <span data-icon="mingcute:alert-fill" className='iconify'></span>
            </div>
            <div className='c-modal__title mb-3'>
              Acción deshabilitada
            </div>
            <p className='c-modal__description'>
              ¡Hola! Te recordamos que para gestionar tus artículos en la web, por favor, regulariza tu pago. 
            </p>
          </div>
          <div className='c-modal__footer d-flex align-items-center justify-content-end'>
            <button className='c-button c-button--white-gray' onClick={toggleModal(false)} >Cerrar</button>
          </div>
        </div>
      </Dialog>

      <Drawer anchor={'right'} open={filterSidebar} onClose={toggleDrawer(false)}>
        <div className='c-sidebar'>
            <div className='c-sidebar__item fw-semibold c-sidebar__item--header position-relative d-flex align-items-center justify-content-between'>
                <span>Filtros</span>
                <button onClick={toggleDrawer(false)} className='c-cross'>
                    <span data-icon="maki:cross" className='iconify'></span>
                </button>
            </div>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordeon('panel1')}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className='p-0 m-0'
                    >
                    <div className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-between'>
                        <span>Estado</span>
                        <span data-icon="octicon:chevron-down-12" className='iconify'></span>
                    </div>
                </AccordionSummary>
                <AccordionDetails className='p-0'>
                    {states.map((item, i) => (
                      <span onClick={() => changeFilter('estado', [item])} key={`estado-${i}`} href="/" className={`c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between ${stateFilter[0] == item ? 'active' : ''}`}>
                          <span>{item}</span>
                      </span>
                    ))}
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordeon('panel2')}>
                <AccordionSummary
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    className='p-0 m-0'
                    >
                    <div className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-between'>
                        <span>Volumen</span>
                        <span data-icon="octicon:chevron-down-12" className='iconify'></span>
                    </div>
                </AccordionSummary>
                <AccordionDetails className='p-0'>
                    {volumes.map((item, i) => (
                      <span onClick={() => changeFilter('volumen', [item])} key={`volume-${i}`} href="/" className={`c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between ${volumeFilter[0] == item ? 'active' : ''}`}>
                          <span>{item}</span>
                      </span>
                    ))}
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordeon('panel3')}>
                <AccordionSummary
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    className='p-0 m-0'
                    >
                    <div className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-between'>
                        <span>Tipo</span>
                        <span data-icon="octicon:chevron-down-12" className='iconify'></span>
                    </div>
                </AccordionSummary>
                <AccordionDetails className='p-0'>
                    {types.map((item, i) => (
                      <span onClick={() => changeFilter('tipo', [item])} key={`tipo-${i}`} href="/" className={`c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between ${typeFilter[0] == item ? 'active' : ''}`}>
                          <span>{item}</span>
                      </span>
                    ))}
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccordeon('panel4')}>
                <AccordionSummary
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                    className='p-0 m-0'
                    >
                    <div className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-between'>
                        <span>Orden</span>
                        <span data-icon="octicon:chevron-down-12" className='iconify'></span>
                    </div>
                </AccordionSummary>
                <AccordionDetails className='p-0'>
                    {orders.map((item, i) => (
                      <span onClick={() => changeFilter('orden', [item])} key={`orden-${i}`} href="/" className={`c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between ${orderFilter[0] == item ? 'active' : ''}`}>
                          <span>{item}</span>
                      </span>
                    ))}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccordeon('panel5')}>
                <AccordionSummary
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                    className='p-0 m-0'
                    >
                    <div className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-between'>
                        <span>Prelistados</span>
                        <span data-icon="octicon:chevron-down-12" className='iconify'></span>
                    </div>
                </AccordionSummary>
                <AccordionDetails className='p-0'>
                    {preslists.map((item, i) => (
                      <span onClick={() => changeFilter('prelistado', [item])} key={`prelistados-${i}`} href="/" className={`c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between ${preslistFilter[0] == item ? 'active' : ''}`}>
                          <span>{item}</span>
                      </span>
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
      </Drawer>
    </div>
  )
}

export default DashboardContainer